import palette from "./palette";

const font = "'Roboto', sans-serif";
const Typography = {
  fontFamily: font,
  h1: {
    color: palette.text.primary,
    fontSize: "50px",
    letterSpacing: "-0.24px",
    lineHeight: "50px",
  },
  h2: {
    color: palette.text.primary,
    fontSize: "40px",
    letterSpacing: "-0.24px",
    lineHeight: "32px",
  },
  h2s: {
    color: palette.text.primary,
    fontSize: "30px",
    letterSpacing: "-0.24px",
    lineHeight: "32px",
  },
  h2w: {
    color: palette.primary.main,
    fontSize: "40px",
    letterSpacing: "-0.24px",
    lineHeight: "32px",
  },
  h3: {
    color: palette.text.primary,
    fontSize: "30px",
    letterSpacing: "-0.06px",
    lineHeight: "50px",
  },
  h3r: {
    color: palette.text.title,
    fontSize: "30px",
    letterSpacing: "-0.06px",
    lineHeight: "50px",
  },
  h3s: {
    color: palette.text.primary,
    fontSize: "25px",
    letterSpacing: "-0.06px",
    lineHeight: "50px",
  },
  h3w: {
    color: palette.primary.main,
    fontSize: "25px",
    letterSpacing: "-0.06px",
    lineHeight: "50px",
  },
  h4: {
    color: palette.text.primary,
    fontSize: "23px",
    letterSpacing: "0.1px",
    lineHeight: "24px",
  },
  h4r: {
    color: palette.text.title,
    fontSize: "18px",
    letterSpacing: "0.1px",
    lineHeight: "24px",
  },
  h4b: {
    color: palette.text.primary,
    fontSize: "20px",
    letterSpacing: "0.1px",
    lineHeight: "35px",
  },
  h5: {
    color: palette.text.primary,
    fontSize: "15px",
    letterSpacing: "0.1px",
    lineHeight: "24px",
  },
  h5w: {
    color: palette.text.secondary,
    fontSize: "15px",
    letterSpacing: "0.1px",
    lineHeight: "24px",
  },
  h6: {
    color: palette.text.primary,
    fontSize: "10px",
    letterSpacing: "0.1px",
    lineHeight: "20px",
  },
  h6w: {
    color: palette.text.secondary,
    fontSize: "10px",
    letterSpacing: "0.1px",
    lineHeight: "20px",
  },
  button: {
    color: palette.text.primary,
    fontSize: "14px",
  },
};

export default Typography;
