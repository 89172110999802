import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

export default function ThankYou() {
  return (
    <div>
      <Grid
        height={"80%"}
        style={{
          paddingTop: "5%",
        }}
      >
        <Grid
          style={{
            paddingTop: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ typography: { xs: "h4b", sm: "h4b", md: "h3" } }}
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Thank you and you've successfully submitted answers.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
