import React from "react";
import { Dialog, IconButton, Stack } from "@mui/material";
import { Image } from "./FormView";
import CancelIcon from "@mui/icons-material/Cancel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

interface Props {
  image: Image;
  open: boolean;
  onClose: () => void;
}

export default function ImageFullDisplay({ image, open, onClose }: Props) {
  const downloadImage = () => {
    // Convert the base64 string to a Blob object
    const byteString = atob(image.value.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `download-${image.fileName}`;

    // Trigger the click event
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack
        direction="row"
        sx={{
          display: "overlay",
        }}
      >
        <IconButton aria-label="close" onClick={() => onClose()}>
          <CancelIcon sx={{ color: "grey" }} />
        </IconButton>
        <IconButton
          aria-label="close"
          onClick={() => {
            downloadImage();
          }}
        >
          <FileDownloadIcon sx={{ color: "grey" }} />
        </IconButton>
      </Stack>
      <img src={image.value} />
    </Dialog>
  );
}
