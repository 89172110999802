import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import ThankYou from "./components/ThankYou";
import FormView from "./components/FormView";
import UserEmailVerify from "./components/UserEmailVerify";

function App() {
  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/form/:id" element={<FormView />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route
                path="/user-email-verify/:token"
                element={<UserEmailVerify />}
              />
            </Routes>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
