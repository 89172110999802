import React, { useEffect, useState } from "react";
import { axiosContext } from "../lib/axios";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Toolbar from "@mui/material/Toolbar";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import ImageFullDisplay from "./ImageFullDisplay";

interface Question {
  id: string;
  displayOrder: number;
  description: string;
  answerType: string;
  answerChoices: string[];
  formName: string;
  projectName: string;
}

export interface Image {
  s3Key: string;
  fileName: string;
  value: string;
}

interface Answer {
  value: string;
  imageList: Array<Image>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function FormView() {
  const [isLoading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [openQuestionId, setOpenQuestionId] = useState("");
  const [message, setMessage] = useState("");
  const [userPlan, setUserPlan] = useState("");
  const [uploadingImage, setUploadingImage] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [questions, setQuestions] = useState<Array<Question>>([]);
  const [answers, setAnswers] = useState<{ [key: string]: Answer }>({});
  const [openImage, setOpenImage] = useState<Image | null>(null);
  const [submitMsg, setSubmitMsg] = useState("");
  const [submittedBy, setSubmittedBy] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      setLoading(true);
      axiosContext()
        .get(`/form-conf/${id}`)
        .then((response) => {
          const qs = response.data?.questions ?? [];
          const msg = response.data?.message.toUpperCase() ?? "";
          setQuestions(qs);
          setOpenAuth(msg === "PASSCODE REQUIRED");
          setMessage(response.data?.message ?? "");
          setUserPlan(response.data?.userPlan ?? "");
          let initAnswers: { [key: string]: Answer } = {};
          for (const q of qs) {
            initAnswers[q.id] = {
              value: "",
              imageList: [],
            };
          }
          setAnswers(initAnswers);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  const handleUpdateAnswer = (id: string, answer: string) => {
    let copy = cloneDeep(answers);
    copy[id].value = answer;
    setAnswers(copy);
  };

  const handleImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    qid: string,
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileNameArr = file.name.split(".");
      let fileFormat = "jpeg";
      if (fileNameArr.length > 1) {
        fileFormat = fileNameArr[fileNameArr.length - 1];
      }
      const s3Key = `${id}/${qid}/${uuidv4()}.${fileFormat}`;
      const reader = new FileReader();
      reader.onload = (event) => {
        let copy = cloneDeep(answers);
        copy[qid].imageList = copy[qid].imageList.concat({
          s3Key: s3Key,
          fileName: file.name,
          value: event.target?.result as string,
        });
        setAnswers(copy);
      };
      reader.readAsDataURL(file);
      const res = await axiosContext().post("/uploadObjectSignedUrl", {
        s3FileKey: s3Key,
      });
      await axios.put(res.data.url, file);
    }
  };
  return (
    <div>
      <Toolbar style={{ marginBottom: "10%" }}>
        <img
          height={"39px"}
          src={
            "https://pub-expose.s3.ap-southeast-2.amazonaws.com/inspectform/logo-250.png"
          }
          alt="Logo"
          className="logo"
        />
        <Typography
          variant="h5"
          sx={{ flexGrow: 1, paddingLeft: "0.2%" }}
          style={{
            fontSize: "22px",
            fontWeight: 1000,
          }}
        >
          <span>
            <span style={{ color: "#B32D2A" }}>In</span>spect
            <span style={{ color: "#B32D2A" }}>Form</span>
          </span>
        </Typography>
      </Toolbar>
      {openAuth && (
        <Dialog open={openAuth}>
          <DialogTitle>
            <Typography>This form is passcode protected</Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              required
              fullWidth
              id="passcode"
              label="Passcode"
              autoFocus
              onChange={(e) => {
                setPasscode(e.target.value);
                setMessage("");
              }}
            />
            {message !== "SUCCEED" && (
              <Typography color={"red"} variant={"h5"}>
                {message}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                const res = await axiosContext().get(`/form-conf/${id}`, {
                  params: {
                    passcode,
                  },
                });
                const message = res.data?.message ?? "";
                const qs = res.data?.questions ?? [];
                setQuestions(qs);
                setMessage(message);
                setOpenAuth(message.toUpperCase() !== "SUCCEED");
                let initAnswers: { [key: string]: Answer } = {};
                for (const q of qs) {
                  initAnswers[q.id] = {
                    value: "",
                    imageList: [],
                  };
                }
                setAnswers(initAnswers);
                setLoading(false);
              }}
            >
              Confirm
            </Button>

            <Button onClick={() => setOpenAuth(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
      {questions.length === 0 && !openAuth && (
        <Typography>Please contact form owner. </Typography>
      )}
      {questions.length > 0 && (
        <Box textAlign={"center"}>
          <Typography variant={"h3"}>Form: {questions[0].formName}</Typography>
          <Typography variant={"h4"} sx={{ marginTop: "2%" }}>
            Project: {questions[0].projectName}
          </Typography>
          {message === "SUCCEED" && userPlan.toUpperCase() === "FREE" && (
            <Typography color={"grey"} variant={"h6"}>
              Add Images feature is not available for free plan
            </Typography>
          )}
        </Box>
      )}
      {questions.length > 0 &&
        questions.map((q) => {
          return (
            <Card
              sx={{
                border: 0.5,
                ":hover": {
                  boxShadow: 20,
                },
                margin: "10%",
                borderRadius: "20px",
              }}
              key={q.id}
            >
              <CardContent>
                <Box textAlign="center">
                  <Typography
                    sx={{ typography: { xs: "h5", sm: "h3", md: "h3" } }}
                  >
                    {q.description}
                  </Typography>

                  {q.answerType === "text" && (
                    <TextField
                      multiline
                      rows={5}
                      fullWidth
                      required={false}
                      label={""}
                      onChange={(e) => handleUpdateAnswer(q.id, e.target.value)}
                    />
                  )}
                  {q.answerType === "singleChoice" && (
                    <RadioGroup
                      onChange={(e) => {
                        if (e.target.checked) {
                          handleUpdateAnswer(q.id, e.target.value);
                        }
                      }}
                      row
                      sx={{
                        display: "flow",
                      }}
                    >
                      {q.answerChoices.map((a) => {
                        return (
                          <FormControlLabel
                            key={`${q.id}-${a}`}
                            value={a}
                            control={<Radio />}
                            label={a}
                          />
                        );
                      })}
                    </RadioGroup>
                  )}
                  {q.answerType === "multipleChoices" && (
                    <Select
                      multiple
                      fullWidth
                      required={false}
                      displayEmpty={true}
                      value={
                        answers[q.id].value
                          ? answers[q.id].value.split("|")
                          : []
                      }
                      onChange={(e) => {
                        let copy = cloneDeep(answers);
                        copy[q.id].value = (e.target.value as string[]).join(
                          "|",
                        );
                        setAnswers(copy);
                      }}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {q.answerChoices.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            <Checkbox
                              checked={answers[q.id].value
                                ?.split("|")
                                .includes(option)}
                            />
                            <ListItemText primary={option} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  {q.answerType === "yesno" && (
                    <RadioGroup
                      onChange={(e) => {
                        if (e.target.checked) {
                          handleUpdateAnswer(q.id, e.target.value);
                        }
                      }}
                      row
                      sx={{
                        display: "flow",
                      }}
                    >
                      <FormControlLabel
                        value={"Yes"}
                        control={<Radio />}
                        label={"Yes"}
                      />
                      <FormControlLabel
                        value={"No"}
                        control={<Radio />}
                        label={"No"}
                      />
                    </RadioGroup>
                  )}
                  {q.answerType === "checkbox" && (
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          handleUpdateAnswer(q.id, "checked");
                        } else {
                          handleUpdateAnswer(q.id, "unchecked");
                        }
                      }}
                    />
                  )}
                  {q.answerType === "n/a" && <div></div>}
                </Box>
              </CardContent>
              <Stack
                justifyContent={"center"}
                direction="row"
                sx={{ paddingTop: "5%" }}
              >
                {" "}
                {answers[q.id].imageList &&
                  answers[q.id].imageList.map((i) => {
                    return (
                      <div style={{ paddingRight: "2%" }} key={i.s3Key}>
                        <IconButton
                          aria-label="close"
                          onClick={() => {
                            let copy = cloneDeep(answers);
                            const imgList = copy[q.id].imageList;
                            copy[q.id].imageList = imgList.filter(
                              (t) => t.s3Key !== i.s3Key,
                            );
                            setAnswers(copy);
                          }}
                          sx={{
                            position: "absolute",
                            paddingRight: "0%",
                            paddingTop: "1%",
                            color: "grey",
                          }}
                        >
                          <DeleteIcon
                            style={{
                              fontSize: 20,
                            }}
                          />
                        </IconButton>
                        <CardMedia
                          sx={{
                            padding: "1em 1em 0 1em",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          component="img"
                          alt="Uploaded Image"
                          height="50"
                          image={i.value}
                          title="Uploaded Image"
                          onClick={() => setOpenImage(i)}
                        />
                        {openImage && openImage?.s3Key === i.s3Key && (
                          <ImageFullDisplay
                            image={i}
                            open={!!openImage}
                            onClose={() => setOpenImage(null)}
                          />
                        )}
                      </div>
                    );
                  })}
              </Stack>
              <CardActions
                style={{
                  justifyContent: "center",
                }}
              >
                {openQuestionId === q.id && (
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="image-upload-input"
                    type="file"
                    onChange={async (e) => {
                      setUploadingImage(true);
                      await handleImageUpload(e, q.id);
                      setUploadingImage(false);
                    }}
                  />
                )}
                {message === "SUCCEED" && userPlan.toUpperCase() !== "FREE" && (
                  <label htmlFor="image-upload-input">
                    <Button
                      variant="contained"
                      component="span"
                      disabled={uploadingImage}
                      onClick={() => setOpenQuestionId(q.id)}
                    >
                      {uploadingImage ? "Uploading" : "Add Images"}
                    </Button>
                  </label>
                )}
              </CardActions>
            </Card>
          );
        })}
      {questions.length > 0 && (
        <Box textAlign={"center"} sx={{ paddingTop: "2%" }}>
          {submitMsg && submitMsg.toUpperCase() !== "SUCCESS" && (
            <Typography color={"red"} variant={"h6"}>
              {submitMsg}
            </Typography>
          )}
          <Box textAlign={"center"}>
            <TextField
              sx={{ width: "50%", paddingBottom: "2%" }}
              required
              id="outlined-required"
              label="Input your name"
              onChange={(e) => setSubmittedBy(e.target.value)}
            />
          </Box>
          <Button
            variant="contained"
            component="span"
            disabled={submitting}
            sx={{ marginBottom: "30%" }}
            onClick={async () => {
              setSubmitting(true);
              let formattedAnswers: Array<{
                questionId: string;
                answer: string;
                images: Array<{
                  s3Key: string;
                  fileName: string;
                }>;
              }> = [];
              for (const qId of Object.keys(answers)) {
                const qAnswer = answers[qId];
                formattedAnswers.push({
                  questionId: qId,
                  answer: qAnswer.value,
                  images: qAnswer.imageList.map((t) => {
                    return {
                      s3Key: t.s3Key,
                      fileName: t.fileName,
                    };
                  }),
                });
              }
              const res = await axiosContext().post("/form-submit", {
                id,
                submittedBy: submittedBy,
                answers: formattedAnswers,
              });
              const msg = res.data?.saveAnswers ?? "";
              if (msg.toUpperCase() === "SUCCESS") navigate("/thank-you");
              else setSubmitting(false);
              setSubmitMsg(msg);
            }}
          >
            Submit
          </Button>
        </Box>
      )}
    </div>
  );
}
