import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, Container, CssBaseline, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { axiosContext } from "../lib/axios";
import { Link } from "react-router-dom";

export default function UserEmailVerify() {
  const { token } = useParams();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      setLoading(true);
      axiosContext()
        .get(`/user-email-verify/${token}`)
        .then((response) => {
          setMessage(response.data?.message.toUpperCase() ?? "");
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  if (loading) return <Typography>Loading page</Typography>;
  return (
    <>
      <Container>
        <CssBaseline />

        <IconButton style={{ marginTop: "1%" }}>
          <img
            height={"50px"}
            src={
              "https://pub-expose.s3.ap-southeast-2.amazonaws.com/inspectform/logo-250.png"
            }
            alt="Logo"
            className="logo"
          />
          <Typography
            variant="h5"
            sx={{ flexGrow: 1, paddingLeft: "2%" }}
            style={{
              fontSize: "18px",
              fontWeight: 1000,
            }}
          >
            <span>
              <span style={{ color: "#B32D2A" }}>In</span>spect
              <span style={{ color: "#B32D2A" }}>Form</span>
            </span>
          </Typography>
        </IconButton>
        {message === "SUCCESS" ? (
          <Typography
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Your email is successfully verified, please login to get started.
          </Typography>
        ) : (
          <Typography sx={{ paddingTop: "10%" }}>
            The email link is invalid, please go to login page to resend email.
          </Typography>
        )}
        <Box textAlign={"center"}>
          <Link
            to={"https://app.inspectform.com"}
            style={{ marginRight: "5%" }}
          >
            <Button variant={"contained"} sx={{ textTransform: "none" }}>
              Sign In
            </Button>
          </Link>
        </Box>
      </Container>
    </>
  );
}
