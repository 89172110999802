// Material helpers
import { createTheme } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
const theme = createTheme({
  palette,
  typography,
});

export default theme;
